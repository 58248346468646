import Vue from 'vue/dist/vue.esm';
import Vuex from 'vuex';
import { HTTPClient } from "api/HTTPClient";
import { getField, updateField } from "vuex-map-fields";

import ProductAction from "./actions/product_action";
import ProductMutation from "./mutations/product_mutation";
import ProductState from "./state/product_state";

import OrderAction from "./actions/order_action";
import OrderMutation from "./mutations/order_mutation";
import OrderState from "./state/order_state";

const httpClient = new HTTPClient();

Vue.use(Vuex)

const store = new Vuex.Store({
  getters: {
    getField,
  },
  state: {
    ...ProductState(),
    ...OrderState(),
  },
  mutations: {
    updateField,
    ...ProductMutation,
    ...OrderMutation,
  },
  actions: {
    ...ProductAction(httpClient),
    ...OrderAction(httpClient),
  }
});

export default store;
