
import { Vue, Component, Prop } from "vue-property-decorator";
import VueBarcode from 'vue-barcode';
import VueQrcode from 'vue-qrcode'

@Component({
  components: {
    VueBarcode,
    VueQrcode
  }
})
export default class Product extends Vue {
  $refs!: any
  @Prop({default: []})
  products

  productsSelected = []
  productSelectedIds = []
  printMode = 'barcode'

  get isSelectedAll() {
    return this.productSelectedIds.length > 0 &&
      this.productSelectedIds.length == this.products.length
  }

  selectProduct(product) {
    const vm = this;
    if (vm.productsSelected.find(p => p.id == product.id)) {
      vm.productsSelected = vm.productsSelected.filter((p) => p.id !== product.id);
    } else {
      vm.productsSelected.push(product);
    }

    vm.productSelectedIds = vm.productsSelected.map((p) => p.id)
  }

  selectAllProduct(products) {
    const vm = this
    if (vm.$refs.selectAll.checked) {
      vm.productsSelected = vm.products
    } else {
      vm.productsSelected = []
    }

    vm.productSelectedIds = vm.productsSelected.map((p) => p.id)
  }

  printBarcode() {
    const vm = this;
    vm.printMode = 'barcode'
    setTimeout(() => {
      window.print()
    }, 100)
  }

  printQrcode() {
    const vm = this;
    vm.printMode = 'qrcode'
    setTimeout(() => {
      window.print()
    }, 200)
  }
}
