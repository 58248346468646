export default (httpClient) => ({
  async createOrder({ commit }, params = {}) {
    const response = await httpClient.request({
      url: "/api/orders.json",
      method: "POST",
      data: params,
    }).catch((error) => {
      commit("createOrderMutation", error.response.data);
      return
    });

    commit("createOrderMutation", response.data);
  },
})
