
import { Vue, Component, Prop } from "vue-property-decorator";
import { Carousel, Slide } from 'vue-carousel';
import { latLng } from "leaflet";
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";

@Component({
  components: {
    Carousel,
    Slide,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip
  }
})
export default class LandingPage extends Vue {
  $refs!: any
  centerLatLng = latLng(18.789412, 98.967944)
  markerLatLng = latLng(18.789412, 98.967944)
  zoom = 15

  map = null
  url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  attribution = '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'

  onMapReady() {
    const vm = this
    vm.map = vm.$refs.map.mapObject
    setTimeout(() => {
      vm.map.invalidateSize();
    }, 500);
  }
}
