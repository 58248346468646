
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class PointOfSale extends Vue {
  STEP = {
    cart: 'cart',
    checkout: 'checkout',
    completed: 'completed',
  }
  step = null
  barcode = null
  keyword = null
  page = 1
  orderLineItems = []
  cashes = [1000, 500, 100, 50, 20]
  payAmount = 0
  discountPrice = 0
  time = new Date().toLocaleString()

  created() {
    const vm = this
    vm.step = vm.STEP.cart
    vm.getProducts()
  }

  get order() {
    return this.$store.state.order
  }
  get product() {
    return this.$store.state.product
  }
  get products() {
    return this.$store.state.products
  }
  get productMeta() {
    return this.$store.state.productMeta
  }
  get subTotal() {
    return this._.sumBy(this.orderLineItems, (lineItem) => {
      return lineItem.product.price * lineItem.qty;
    });
  }
  get total() {
    return this.subTotal - this.discountPrice;
  }
  get chance() {
    return this.payAmount - this.total
  }

  getProducts() {
    const vm = this
    vm.$store.dispatch('getProducts', {
      keyword: vm.keyword,
      page: vm.page,
    })
  }

  saerchByBarcode() {
    const vm = this
    vm.$store.dispatch('searchProductByBarcode', {
      barcode: vm.barcode
    })
  }

  addPayAmount(amount, fill) {
    const vm = this
    if (fill) {
      vm.payAmount = amount
    } else {
      vm.payAmount += amount
    }
  }

  addOrderLineItem(product) {
    const vm = this
    let findProduct = vm._.find(vm.orderLineItems, (item) => +item.product.id === +product.id)

    if (findProduct) {
      findProduct.qty++
    } else {
      vm.orderLineItems.push({
        qty: 1,
        product: product,
        price: product.price,
      })
    }
  }

  removeOrderLineItem(index) {
    const vm = this
    vm.orderLineItems.splice(index, 1)
  }

  checkout() {
    const vm = this
    let data = {
      order_line_items_attributes: vm.orderLineItems.map((item) => {
        return {
          qty: item.qty,
          product_id: item.product.id
        }
      }),
      discount_price: vm.discountPrice
    }
    vm.$store.dispatch('createOrder', { order: data })
  }

  printReceipt() {
    window.print()
  }

  @Watch('page')
  onPageChanged(valueChanged) {
    const vm = this
    vm.getProducts()
  }

  @Watch('product')
  onProductChanged(valueChanged) {
    const vm = this

    if (vm._.isEmpty(valueChanged.error) && !vm._.isEmpty(valueChanged)) {
      vm.addOrderLineItem(vm.product)
    } else {
      this.$toasted.show(valueChanged.error, { type: 'error' });
    }
    vm.barcode = null
  }

  @Watch('step')
  onStepChanged(valueChanged, oldValue) {
    const vm = this

    if (oldValue === vm.STEP.completed && valueChanged === vm.STEP.cart) {
      vm.orderLineItems = []
      vm.payAmount = 0
      vm.discountPrice = 0
    }
  }

  @Watch('order')
  onOrderChanged(valueChanged) {
    const vm = this

    if (valueChanged) {
      vm.step = vm.STEP.completed
      vm.getProducts()
    }
  }
}
