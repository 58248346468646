import Vue from "vue/dist/vue.esm";
import TurbolinksAdapter from "vue-turbolinks";
import VueLodash from 'vue-lodash'
import VueCurrencyFilter from 'vue-currency-filter'
import Toasted from 'vue-toasted';
import lodash from "lodash";

import store from "store";
import vuetify from "../plugins/vuetify";

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

import TurbolinksCustom from "../utilities/tubolinks-custom";
Vue.prototype.$turbolinks = TurbolinksCustom;

Vue.use(TurbolinksAdapter);
Vue.use(VueLodash, { lodash: lodash });
Vue.use(VueCurrencyFilter, {
  symbol : '',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: false
})

import Product from "pages/product.vue";
import ProductDetail from "pages/product_detail.vue";
import ManufacturerDetail from "pages/manufacturer_detail.vue";
import PointOfSale from "pages/point_of_sale.vue";
import LandingPage from "pages/landing_page.vue";

document.addEventListener("turbolinks:load", () => {
  Vue.use(Toasted, {
    duration: 3000,
    fullWidth: true,
    position: 'bottom-left'
  })

  if (document.querySelector('[data-behavior="vue"]')) {
    new Vue({
      el: '[data-behavior="vue"]',
      store,
      vuetify,
      components: {
        Product,
        PointOfSale,
        LandingPage,
        ProductDetail,
        ManufacturerDetail,
      }
    });
  }
});
