export default (httpClient) => ({
  async getProducts({ commit }, params = {}) {
    const response = await httpClient.request({
      url: "/api/products.json",
      method: "GET",
      params: params,
    }).catch((error) => {
      commit("getProductsMutation", error.response.data);
      return
    });

    commit("getProductsMutation", response.data);
  },
  async searchProductByBarcode({ commit }, params = {}) {
    const response = await httpClient.request({
      url: "/api/search_products/barcode.json",
      method: "GET",
      params: params,
    }).catch((error) => {
      commit("searchProductByBarcodeMutation", error.response.data);
      return
    });

    commit("searchProductByBarcodeMutation", response.data);
  },
})
