
import { Vue, Component, Prop } from "vue-property-decorator";
import CoolLightBox from 'vue-cool-lightbox'

@Component({
  components: {
    CoolLightBox,
  }
})
export default class ProductDetail extends Vue {
  @Prop({default: []})
  profileImages
  profileImageIndex = null

  @Prop({default: []})
  houseImages
  houseImageIndex = null

  @Prop({default: []})
  productImages
  productImageIndex = null
}
